import { formatMonthYear } from '../../helpers';
import dayjs from '../../config/dayjs';

const currentBehaviorArrayTransformer = (array) => {
  // show values with dates as columns
  const itemsArray = Object.keys(array?.[0] ?? {});
  const columnsArray = array
    ?.map((item, index) => ({
      index,
      date: item.date,
      timestamp: dayjs(item.date),
      label: formatMonthYear(dayjs(item.date)),
    }))
    .sort((a, b) => a.timestamp.diff(b.timestamp));
  const filteredArray = itemsArray.filter(
    (el) => !['__typename', 'id', 'createdAt', 'date'].includes(el),
  );
  const rowsArray = filteredArray.map((item, index) => ({
    id: index,
    label: item,
    firstValue: array?.find((el) => el.date === columnsArray[0].date)[item],
    secondValue: array?.find((el) => el.date === columnsArray[1].date)[item],
    thirdValue: array?.find((el) => el.date === columnsArray[2].date)[item],
    fourthValue: array?.find((el) => el.date === columnsArray[3].date)[item],
  }));
  return { rowsArray, columnsArray };
};

export default currentBehaviorArrayTransformer;
